import { page, bootstrapSelect, introJS, dateRangePicker } from '../../init'

$(function() {
  if (page.controllerPath().startsWith('user/analysis')) {
    if (page.controllerPath() === 'user/analysis/log_activities') return

    console.debug('[JS] daterangepicker')
    dateRangePicker.trigger('#reportrange')

    console.debug('[JS] bootstrap-select')
    bootstrapSelect.trigger({showSubtext: true})

    console.debug('[JS] intro.js')
    introJS.onClick('#intro-start')
  }
})

// NOTE: the order of loading imports has meaning.
//       basicSetup: https://github.com/codemirror/basic-setup/blob/main/src/codemirror.ts
import { dropCursor, keymap, highlightSpecialChars, drawSelection, highlightActiveLine, highlightActiveLineGutter, EditorView, lineNumbers } from '@codemirror/view'
import { EditorState, Compartment } from '@codemirror/state'
import { syntaxHighlighting, defaultHighlightStyle, indentOnInput, bracketMatching } from '@codemirror/language'
import { defaultKeymap, history, historyKeymap, indentWithTab } from '@codemirror/commands'
import { autocompletion, closeBrackets, closeBracketsKeymap } from '@codemirror/autocomplete'
import { linter, lintKeymap, lintGutter } from '@codemirror/lint'
import Linter from 'eslint4b-prebuilt/dist/eslint4b.es.js'

import { javascript, esLint } from '@codemirror/lang-javascript'
import { json, jsonParseLinter } from '@codemirror/lang-json'
// import { css } from '@codemirror/lang-css'
import { sass } from '@codemirror/lang-sass'
import { html } from '@codemirror/lang-html'
import { liquid } from '@codemirror/lang-liquid'

export default class CodeMirrorWrapper {
  constructor({ textarea, mode, isReadOnly = false, onChange = null }) {
    this.textarea = textarea
    this.mode = mode
    this.isReadOnly = isReadOnly
    this.onChange = onChange
    this._view = null
    this._readOnlyCompartment = new Compartment
    this._highlightActiveLineCompartment = new Compartment
  }

  applyTo(callback = null) {
    const esLintConfig = {
      parserOptions: {
        // ecmaVersion: 11,
      },
      env: {
        browser: true, // Browser's global env values
        jquery: true,
        // es6: true,
        es2020: true,
      },
      rules: {
        'no-cond-assign': 'error',
        'no-console': 'warn',
        'no-constant-condition': 'error',
        'no-control-regex': 'error',
        'no-debugger': 'error',
        'no-dupe-args': 'error',
        'no-dupe-keys': 'error',
        'no-duplicate-case': 'error',
        'no-empty-character-class': 'error',
        'no-empty': 'warn',
        'no-ex-assign': 'error',
        'no-extra-boolean-cast': 'error',
        'no-extra-semi': 'warn',
        'no-func-assign': 'error',
        'no-inner-declarations': 'warn',
        'no-invalid-regexp': 'warn',
        'no-irregular-whitespace': 'warn',
        'no-obj-calls': 'warn',
        'no-regex-spaces': 'warn',
        'no-sparse-arrays': 'warn',

        'no-undef': 'warn',
      }
    }

    const state = EditorState.create({
      doc: this.textarea.value, // default value
      extensions: [
        lineNumbers(),
        this._highlightActiveLineCompartment.of([]), // highlight: line and gutter
        history(),
        drawSelection(),
        dropCursor(),
        indentOnInput(),
        syntaxHighlighting(defaultHighlightStyle, { fallback: true }),
        bracketMatching(),
        closeBrackets(),
        autocompletion(),
        highlightSpecialChars(),
        keymap.of([
          ...defaultKeymap,
          ...historyKeymap,
          ...lintKeymap,
          ...closeBracketsKeymap,
          indentWithTab,
        ]),
        // Lang
        // ... (this.mode === 'javascript') ? [javascript(), lintGutter(), linter(esLint(new Linter()))] : [],
        ... (this.mode === 'javascript') ? [javascript(), lintGutter(), linter(esLint(new Linter(), esLintConfig))] : [],
        ... (this.mode === 'json') ? [json(), lintGutter(), linter(jsonParseLinter())] : [],
        ... (this.mode === 'scss') ? [sass()] : [],
        ... (this.mode === 'html') ? [html()] : [],
        ... (this.mode === 'liquid') ? [liquid()] : [],
        // Others
        EditorView.domEventHandlers({
          focus: (_event, view) => {
            if (!view.state.doc.toString().length) return
            view.dispatch({
              effects: [
                this._highlightActiveLineCompartment.reconfigure([
                  highlightActiveLine(),
                  highlightActiveLineGutter()
                ]),
              ]
            })
          },
          blur: (event, view) => {
            view.dispatch({
              effects: [
                this._highlightActiveLineCompartment.reconfigure([]),
              ],
            })
            this.textarea.value = view.state.doc.toString()
            if (this.onChange) this.onChange({ value: view.state.doc.toString(), event: event, view: view })
          },
        }),
        this._readOnlyCompartment.of(
          EditorState.readOnly.of(this.isReadOnly)
        ),
      ],
    })
    this._view = this._editorFromTextArea(this.textarea, state)

    // Make background color darker
    if (this.isReadOnly) this.enableReadOnly()
    if (callback) callback(this._view.dom)
  }

  _editorFromTextArea(textarea, state) {
    const view = new EditorView({
      state: state,
      // parent: textarea,
    })
    textarea.parentNode.insertBefore(view.dom, textarea)
    textarea.style.display = 'none'
    // if (textarea.form) textarea.form.addEventListener('submit', () => {
    //   console.debug('submit', view.state.doc.toString())
    //   textarea.value = view.state.doc.toString()
    // })
    return view
  }

  toggleReadOnly() {
    if (!this._view) { throw 'CodeMirror is not initialized' }
    if (this.isReadOnly) {
      this.isReadOnly = false
      // this._view.setOption('readOnly', false)
      this._view.dispatch({
        effects: this._readOnlyCompartment.reconfigure(EditorState.readOnly.of(false))
      })
      this._view.dom.parentElement.classList.remove('read-only')
    } else {
      this.isReadOnly = true
      this.enableReadOnly()
    }
  }

  enableReadOnly() {
    // this._view.setOption('readOnly', 'nocursor')
    this._view.dispatch({
      effects: this._readOnlyCompartment.reconfigure(EditorState.readOnly.of(true))
    })
    this._view.dom.parentElement.classList.add('read-only')
  }

  get view() {
    return this._view
  }
}
